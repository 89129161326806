/* Color Palette */
/* #0B0B0B #181B1F #63D3BB #7975D5 #2F4FC0 #666C6F #A1ACB1 */
/* End Color Palette */
/* HTML */
html {
  scroll-behavior: auto !important;
}
body {
  font-family: "Barlow", sans-serif;
  font-size: 16px;
  background-color: #181b1f ;
  color: white;
  min-height: 100vh;
  position: relative;
  z-index: 2;
}
body h1 {
  font-family: "Poppins", sans-serif;
  padding-top: 1rem;
  font-weight: 600;
}
h2 {
  font-family: 'Inter', sans-serif;
  font-weight: 600;
  font-size: 16px;
  font-style: bold;
  text-transform: uppercase;
  color: #63D3BB;
}
/* Custom Button */
.custom-button {
  background-color: #63d3bb;
  padding: 10px 20px;
  border: none;
  color: #0b0b0b;
  font-weight: 500;
  border-radius: 2rem;
  transition: background-color 0.3s ease-in-out, color 0.2s ease-in-out;
}
.custom-button:hover {
  background-color: #7975d5;
  color: white;
}
/* End Custom Button */
/* Cards */
.card {
  border-radius: 1.5rem;
  overflow: hidden;
}
/* End Cards */
/* End HTML */
/* Carousel */
.carousel-item {
  height: 100vh;
  background-image: url(../images/background.png);
  background-size: cover;
  background-attachment: fixed;
  background-repeat: no-repeat;
}
.carousel-item img {
  height: 100vh;
  object-fit: cover;
}
.carousel-caption {
  position: absolute;
  text-align: center;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: white;
}
.carousel-caption h1 {
  padding: 0;
}
@media (max-width: 768px) {
  .carousel-caption {
      top: 30%;
      width: 100%;
      left: 0;
      right: 0;
      transform: none;
  }
}
.carousel-caption h1 {
  font-weight: 800;
  font-size: 50px;
}
.carousel-caption i {
  font-size: 30px;
}
/* End Carousel */
/* Navbar */
.navbar {
  transform: translateY(-100%);
  transition: transform 0.3s ease-in-out;
}
.navbar.visible {
  transform: translateY(0);
}
.navbar {
  background: #0b0b0b;
  padding: 2.5vh;
}
.navbar-nav .nav-link {
  position: relative;
  color: #9caaaf;
  font-weight: 600;
}
.navbar-nav .nav-link:hover {
  position: relative;
  color: white;
}
.navbar-nav .nav-link::before,
.navbar-nav .nav-link::after {
  content: "";
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  height: 2px;
  background-color: #63d3bb;
  transform: scaleX(0);
  transform-origin: center;
  transition: transform 0.3s ease-in-out;
}
.navbar-nav .nav-link:hover::before,
.navbar-nav .nav-link:hover::after {
  transform: scaleX(0.7);
}
.navbar-nav .nav-link.active {
  color: white;
}
.navbar-nav .nav-link.active::before,
.navbar-nav .nav-link.active::after {
  transform: scaleX(0.7);
}
@media (max-width: 768px) {
  .navbar-nav .nav-link.active::before,
  .navbar-nav .nav-link.active::after {
      transform: scaleX(0.1);
  }
}
/* End Navbar */
/* Projects */
#Projects {
  font-family: 'Poppins', sans-serif;
  background-color: #181b1f;
}
#Projects img {
  width: 100%;
  object-fit: cover;
}
#Projects .card-title {
  font-family: 'Inter', sans-serif;
  font-weight: 800;
  font-size: 2rem;
}
#Projects .card-subtitle {
  font-weight: 600;
  font-size: 1rem;
}
#Projects .card-text {
  padding-top: 0.5rem;
  font-weight: 300;
  color: #666262;
  font-size: 1.1rem;
}
/* End Projects */
/* Team */
#Team {
  background-color: #181b1f;
}
#Team .card {
  padding: 0;
  border: none;
}
#Team img {
  width: 100%;
  object-fit: cover;
}
#Team h3 {
  font-size: 1.5rem;
  font-weight: 600;
}
#Team i {
  font-size: 30px;
}
@media (min-width: 768px) {
  #Team .row {
    gap: 1px;
  }
}
/* End Team */
/* Contact */
#Contact {
  background-color: #181b1f;
  box-shadow: 0px 40px 5px rgba(0, 0, 0, 0.1);
}
#Contact .card {
  background-color: #0b0b0b;
  color: white;
}
#Contact h2 {
  font-weight: 700;
  font-size: 30px;
}
#Contact a {
  text-decoration: none;
  color: white;
  transition: all 0.3s ease-in-out;
}
#Contact a:hover {
  color: #63D3BB;
}
#Contact .Logo {
  width: 100%;
}
#Contact .Emblem {
  width: 50%;
}
#Contact form input, #Contact form textarea {
  border-radius: 0rem;
  width: 80%;
  margin: auto;
  display: block;
}
@media (max-width: 768px) {
  #Contact #Email {
    padding-bottom: 50px;
  }
}
#Contact iframe {
  border-radius: 1.5rem;
  transition: all 0.3s ease-in-out;
}
#Contact iframe:hover {
  border-radius: 0;
}
/* End Contact */
/* Footer */
footer {
  background-image: url(../images/footer-bg.png?as=webp);
  background-color: #121212;
  background-position: 56% 100%;
  background-attachment: fixed;
  background-repeat: no-repeat;
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: -1;
  overflow: hidden;
}
footer .container-fluid {
  width: 100%;
  padding: 20px 0;
}
footer h3 {
  font-family: 'Poppins', sans-serif;
  font-size: 30px;
  font-weight: 600;
  color: white;
}
footer h4 {
  font-family: 'Inter', sans-serif;
  font-weight: 400;
  font-size: 16px;
  color: #A1ACB1;
}
footer p {
  font-family: 'Inter', sans-serif;
  font-weight: 400;
  font-size: 15px;
  color: #666C6F;
}
footer .link {
  text-decoration: none;
  color: #A1ACB1;
  transition: color 0.1s ease-in-out;
}
footer .link:hover {
  color: #7975D5;
}
@media (max-width: 768px) {
  footer .row-item {
    padding-top: 2vh;
  }
  footer {
    text-align: center;
  }
}
/* End Footer */